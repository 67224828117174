<template>
  <div class="myAssResult">
    <!-- 我的考核结果 -->
    <van-nav-bar
      :left-text="$t('myAssResult.resTxt1')"
      left-arrow
      @click-left="onClickLeft"
      @click-right="showPicker = true"
      :fixed="true"
    >
      <template #right>
        {{ khtime.khtimename }} <van-icon name="arrow-down" size="18" />
      </template>
    </van-nav-bar>
    <div class="content">
      <van-tabs
        v-model="activeName"
        color="#2B8DF0"
        title-active-color="#2B8DF0"
        @change="changeAct"
      >
        <van-tab
          v-if="$t('myAssResult.resTxt2') && khtime.is360"
          :title="$t('myAssResult.resTxt2') + `(${khtime.per360})`"
          name="360Tag"
        ></van-tab>
        <van-tab
          v-if="$t('myAssResult.resTxt3') && khtime.iskpi"
          :title="$t('myAssResult.resTxt3') + `(${khtime.perkpi})`"
          name="KPITag"
        ></van-tab>
        <van-tab
          v-if="$t('myAssResult.resTxt6') && khtime.isgs"
          :title="$t('myAssResult.resTxt6') + `(${khtime.pergs})`"
          name="GSTag"
        ></van-tab>
      </van-tabs>
      <van-pull-refresh
        :class="{
          hasss:
            activeName == 'KPITag' &&
            !kpiBaseInfo.isss &&
            kpiBaseInfo.canss &&
            kpiBaseInfo.sstype == '按结果申诉'
        }"
        v-model="isLoadingAwait"
        @refresh="onRefreshAwait"
      >
        <van-list
          v-model="loadingAwait"
          :finished="finishedAwait"
          :finished-text="$t('module.noMore')"
          @load="onLoadAwait"
        >
          <!-- 360考核结果 -->
          <div v-if="activeName == '360Tag'">
            <div class="zb_title" v-for="(it, ind) in assessList" :key="ind">
              <div class="til_div">{{ it.name }}</div>
              <div
                class="zb_group"
                v-for="(item, index) in it.origin"
                :key="index"
              >
                <van-cell-group>
                  <van-cell
                    class="top_cell"
                    center
                    :title="item.indicatorname"
                    :value="item.typename"
                  />
                  <van-cell class="btm_cell" center>
                    <template #title>
                      <span class="custom-title">{{ item.indicatordesp }}</span>
                    </template>
                    <template #label>
                      <span class="custom-score">{{ item.score }}</span>
                      {{ $t('jxMyAssResultsList.arlTxt1') }}
                    </template>
                  </van-cell>
                </van-cell-group>
              </div>
            </div>
          </div>
          <!-- GS任务结果 -->
          <div v-else-if="activeName == 'GSTag'">
            <div
              class="zb_group"
              v-for="(item, index) in assessList"
              :key="index"
            >
              <van-cell
                class="ass_cell2"
                size="large"
                :title="item.taskname"
                :label="`${item.empname}-${item.deptname}`"
                @click="goToApply(item)"
              >
                <template slot="label">
                  {{ item.empname }}-{{ item.deptname }}<br />
                  周期：{{ item.khtimename }}
                </template>
                <template>
                  <div style="color:red;font-weight: bold !important;">
                    权重：{{ item.taskper }}
                  </div>
                  <div>{{ item.reqdate }}</div>
                  <!-- v-show="item.totalscore" -->
                  <div>实际得分：{{ item.totalscore }}</div>
                </template>
              </van-cell>
            </div>
          </div>
          <!-- KPI考核结果 -->
          <div v-else>
            <van-collapse v-model="activeNames1" class="first_coll">
              <div v-if="assessList.length > 0" class="zf">
                <svg-icon icon-class="fenshu"></svg-icon>总分:{{
                  assessList[0].lastscore
                }}分({{ assessList[0].khres }})
              </div>
              <van-collapse-item
                v-for="(it, ind) in assessList"
                :key="ind"
                :title="it.name"
                :name="ind"
              >
                <van-collapse
                  v-model="activeNames2"
                  class="second_coll"
                  :class="showSecondColl ? 'disabled_coll' : ''"
                >
                  <van-collapse-item
                    v-for="(item, idx) in it.origin"
                    :key="idx"
                    :name="ind + 'c' + idx"
                    :disabled="showSecondColl"
                  >
                    <!-- 按结果申诉、按指标申诉 -->
                    <template #title>
                      <div class="second_title">
                        <div class="second_name">{{ item.itemname }}</div>
                        <van-tag
                          plain
                          v-if="
                            !kpiBaseInfo.isss &&
                              kpiBaseInfo.canss &&
                              kpiBaseInfo.sstype == '按指标申诉'
                          "
                          type="primary"
                          size="medium"
                          style="margin-right: 5px"
                          :color="'#2b8df0'"
                          @click="toShensu(item)"
                          >{{ $t('jxMyAssResultsList.arlTxt2') }}</van-tag
                        >
                        <!-- v-show="showIndicatorScore"  2023/10/05注释掉是因为没有定义这个变量-->
                        <div class="second_score">
                          {{ item.totalscore
                          }}{{ $t('jxMyAssResultsList.arlTxt1') }}
                        </div>
                      </div>
                    </template>
                    <!-- 内容 -->
                    <div class="second_details">
                      <div class="thrid_desc">
                        <div class="itemdesc">{{ item.itemdesc }}</div>
                        <div class="desc">
                          <div class="thrid_per">
                            {{ $t('jxMyAssResultsList.arlTxt3')
                            }}{{ item.itemper }}
                          </div>
                          <div class="thrid_tg">
                            {{ item.tgvalue
                            }}{{ $t('jxMyAssResultsList.arlTxt1') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 评分规则 -->
                    <van-collapse
                      class="thrid_coll"
                      v-model="activeNames3"
                      @change="item.showgz = !item.showgz"
                    >
                      <van-collapse-item
                        :name="ind + 'gz' + idx"
                        :title="$t('jxMyAssResultsList.arlTxt4')"
                      >
                        <template #right-icon>
                          <div class="right-icon">
                            {{
                              `${
                                item.showgz
                                  ? $t('jxMyAssResultsList.arlTxt5')
                                  : $t('jxMyAssResultsList.arlTxt6')
                              }`
                            }}
                            <van-icon
                              :name="item.showgz ? 'arrow-up' : 'arrow-down'"
                            />
                          </div>
                        </template>
                        <div class="thrid_desc">
                          <div class="desp">{{ item.pfdesp }}</div>
                        </div>
                      </van-collapse-item>
                    </van-collapse>
                    <!-- 评分 -->
                    <div
                      class="select_container"
                      v-if="
                        $t('jxDataSocreDetail.txt20') ||
                          $t('jxDataSocreDetail.txt21') ||
                          $t('jxDataSocreDetail.txt22')
                      "
                    >
                    
                      <div
                        class="select_input"
                        v-if="
                          item.idtype != '定性' && $t('jxDataSocreDetail.txt20')
                        "
                      >
                        <h3>{{ $t('jxDataSocreDetail.txt20') }}</h3>
                        <!--  :disabled="item.idtype == '定量' || checkflag == 2 || checkflag == 3" -->
                        <div>
                          <input disabled type="text" v-model="item.tgvalue" />
                        </div>
                      </div>
                      <div
                        class="select_input"
                        v-if="
                          item.idtype != '定性' && $t('jxDataSocreDetail.txt21')
                        "
                      >
                        <h3>{{ $t('jxDataSocreDetail.txt21') }}</h3>
                        <!--  :disabled="item.idtype == '定量' || checkflag == 2 || checkflag == 3" -->
                        <div>
                          <input
                            disabled
                            type="text"
                            v-model="item.alarmvalue"
                          />
                        </div>
                      </div>
                      <div
                        class="select_input"
                        v-if="
                          item.idtype != '定性' && $t('jxDataSocreDetail.txt22')
                        "
                      >
                        <h3>{{ $t('jxDataSocreDetail.txt22') }}</h3>
                        <!--  :disabled="item.idtype == '定量' || checkflag == 2 || checkflag == 3" -->
                        <div>
                          <input
                            disabled
                            type="text"
                            v-model="item.realscore"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="select_container"
                      v-if="
                        $t('jxDataSocreDetail.txt13') ||
                          $t('jxDataSocreDetail.txt14') ||
                          $t('jxDataSocreDetail.txt15')
                      "
                    >
                      <div
                        v-if="
                       
                            (item.idtype != '定量' ||
                              (item.idtype == '定量')) &&
                            $t('jxDataSocreDetail.txt13')
                        "
                        class="select_input"
                      >
                        <h3>{{ $t('jxDataSocreDetail.txt13') }}</h3>
                        <!--  :disabled="item.idtype == '定量' || checkflag == 2 || checkflag == 3" -->
                        <div
                          style="display: flex;align-items: center;justify-content: center;"
                        >
                          <input
                            disabled
                            type="text"
                            v-model="item.itemscore"
                            :style="{ width: item.zpunit ? '60%' : '' }"
                          />
                          <span>{{ item.zpunit }}</span>
                        </div>
                      </div>
                      <div
                        v-if="
                        
                            (item.idtype != '定量' ||
                              (item.idtype == '定量')) &&
                            $t('jxDataSocreDetail.txt14')
                        "
                        class="select_input"
                      >
                        <h3>{{ $t('jxDataSocreDetail.txt14') }}</h3>
                        <!-- :disabled="item.idtype == '定量' || checkflag == 1 || checkflag == 3" -->
                        <div>
                          <input
                            disabled
                            type="text"
                            v-model="item.zjsj_score"
                          />
                        </div>
                      </div>
                      <div
                        v-if="
                            (item.idtype != '定量' ||
                              (item.idtype == '定量')) &&
                            $t('jxDataSocreDetail.txt15')
                        "
                        class="select_input"
                      >
                        <h3>{{ $t('jxDataSocreDetail.txt15') }}</h3>
                        <!--  :disabled="item.idtype == '定量' || checkflag == 1 || checkflag == 2" -->
                        <div>
                          <input
                            disabled
                            type="text"
                            v-model="item.jjsj_score"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- 统计项目 -->
                    <van-collapse
                      class="thrid_coll"
                      v-model="activeNames4"
                      @change="item.showtj = !item.showtj"
                    >
                      <van-collapse-item
                        :name="ind + 'tj' + idx"
                        :title="$t('jxMyAssResultsList.arlTxt7')"
                      >
                        <template #right-icon>
                          <div class="right-icon">
                            {{
                              `${
                                item.showtj
                                  ? $t('jxMyAssResultsList.arlTxt5')
                                  : $t('jxMyAssResultsList.arlTxt6')
                              }`
                            }}
                            <van-icon
                              :name="item.showtj ? 'arrow-up' : 'arrow-down'"
                            />
                          </div>
                        </template>
                        <div class="thrid_desc">
                          <div class="desp">
                            <div
                              class="Sta_poj"
                              v-for="(el, eid) in item.child"
                              :key="eid"
                            >
                              <div>{{ el.itemname }}</div>
                              <div>{{ el.itemvalue }}</div>
                            </div>
                          </div>
                        </div>
                      </van-collapse-item>
                    </van-collapse>
                    <!-- 自我描述 -->
                    <van-collapse
                      class="thrid_coll"
                      v-model="activeNames5"
                      @change="item.showzw = !item.showzw"
                      v-if="is_zp == 'true'"
                    >
                      <van-collapse-item
                        :name="ind + 'zw' + idx"
                        :title="$t('jxMyAssResultsList.arlTxt8')"
                      >
                        <template #right-icon>
                          <div class="right-icon">
                            {{
                              `${
                                item.showzw
                                  ? $t('jxMyAssResultsList.arlTxt5')
                                  : $t('jxMyAssResultsList.arlTxt6')
                              }`
                            }}
                            <van-icon
                              :name="item.showzw ? 'arrow-up' : 'arrow-down'"
                            />
                          </div>
                        </template>
                        <div class="thrid_desc">
                          <div class="desp">{{ item.zpdesc }}</div>
                        </div>
                      </van-collapse-item>
                    </van-collapse>
                    <!-- 直接上级描述 -->
                    <van-collapse
                      class="thrid_coll"
                      v-model="activeNames6"
                      @change="item.zjdespstatus = !item.zjdespstatus"
                      v-if="is_zjp == 'true'"
                    >
                      <van-collapse-item
                        :name="ind + 'zw' + idx"
                        :title="$t('jxMyAssResultsList.arlTxt9')"
                      >
                        <template #right-icon>
                          <div class="right-icon">
                            {{
                              `${
                                item.zjdespstatus
                                  ? $t('jxMyAssResultsList.arlTxt5')
                                  : $t('jxMyAssResultsList.arlTxt6')
                              }`
                            }}
                            <van-icon
                              :name="
                                item.zjdespstatus ? 'arrow-up' : 'arrow-down'
                              "
                            />
                          </div>
                        </template>
                        <div class="thrid_desc">
                          <div class="desp">{{ item.zjdesp }}</div>
                        </div>
                      </van-collapse-item>
                    </van-collapse>
                    <!-- 间接上级描述 -->
                    <van-collapse
                      class="thrid_coll"
                      v-model="activeNames7"
                      @change="item.jjdespstatus = !item.jjdespstatus"
                      v-if="is_jjp == 'true'"
                    >
                      <van-collapse-item
                        :name="ind + 'zw' + idx"
                        :title="$t('jxMyAssResultsList.arlTxt10')"
                      >
                        <template #right-icon>
                          <div class="right-icon">
                            {{
                              `${
                                item.jjdespstatus
                                  ? $t('jxMyAssResultsList.arlTxt5')
                                  : $t('jxMyAssResultsList.arlTxt6')
                              }`
                            }}
                            <van-icon
                              :name="
                                item.jjdespstatus ? 'arrow-up' : 'arrow-down'
                              "
                            />
                          </div>
                        </template>
                        <div class="thrid_desc">
                          <div class="desp">{{ item.jjdesp }}</div>
                        </div>
                      </van-collapse-item>
                    </van-collapse>
                    <!-- jjdespstatus -->
                    <!-- 启用自评/启用直接评/启用间接评 -->
                    <div class="pingjia">
                      <div class="pj_sco">
                        <div
                          class="text"
                          style="
                            display: inline-block;
                            width: 22px;
                            height: 22px;
                            text-align: center;
                          "
                        >
                          {{ $t('jxMyAssResultsList.arlTxt11') }}
                        </div>
                        <span class="time"
                          >{{ item.realscore
                          }}{{ $t('jxMyAssResultsList.arlTxt1') }}</span
                        >
                      </div>
                      <div class="pj_sco">
                        <div
                          v-if="is_zp == 'true'"
                          class="text"
                          style="
                            display: inline-block;
                            width: 22px;
                            height: 22px;
                            text-align: center;
                          "
                        >
                          {{ $t('jxMyAssResultsList.arlTxt12') }}
                        </div>
                        <span v-if="is_zp == 'true'" class="time"
                          >{{ item.itemscore
                          }}{{ $t('jxMyAssResultsList.arlTxt1') }}</span
                        >
                      </div>
                      <div class="pj_sco">
                        <div
                          v-if="is_zjp == 'true'"
                          class="text"
                          style="
                            display: inline-block;
                            width: 22px;
                            height: 22px;
                            text-align: center;
                          "
                        >
                          {{ $t('jxMyAssResultsList.arlTxt13') }}
                        </div>
                        <span v-if="is_zjp == 'true'" class="time"
                          >{{ item.zjsj_score
                          }}{{ $t('jxMyAssResultsList.arlTxt1') }}</span
                        >
                      </div>
                      <div class="pj_sco">
                        <div
                          v-if="is_jjp == 'true'"
                          class="text"
                          style="
                            display: inline-block;
                            width: 22px;
                            height: 22px;
                            text-align: center;
                          "
                        >
                          {{ $t('jxMyAssResultsList.arlTxt14') }}
                        </div>
                        <span v-if="is_jjp == 'true'" class="time"
                          >{{ item.jjsj_score
                          }}{{ $t('jxMyAssResultsList.arlTxt1') }}</span
                        >
                      </div>
                    </div>

                    <div class="select_container">
                      <div class="select_input" v-if="item.idtype == '定量'">
                        <h3>{{ $t('jxDataSocreDetail.txt20') }}</h3>
                        <!--  :disabled="item.idtype == '定量' || checkflag == 2 || checkflag == 3" -->
                        <div>
                          <input
                            :disabled="true"
                            type="text"
                            v-model="item.tgvalue"
                          />
                        </div>
                      </div>
                      <div class="select_input" v-if="item.idtype == '定量'">
                        <h3>{{ $t('jxDataSocreDetail.txt21') }}</h3>
                        <!--  :disabled="item.idtype == '定量' || checkflag == 2 || checkflag == 3" -->
                        <div>
                          <input
                            :disabled="true"
                            type="text"
                            v-model="item.alarmvalue"
                          />
                        </div>
                      </div>
                      <div class="select_input" v-if="item.idtype == '定量'">
                        <h3>{{ $t('jxDataSocreDetail.txt22') }}</h3>
                        <!--  :disabled="item.idtype == '定量' || checkflag == 2 || checkflag == 3" -->
                        <div>
                          <input
                            :disabled="true"
                            type="text"
                            v-model="item.realscore"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- 直接上级和间接上级评价 -->
                    <div class="zhijie_jianjie_pj">
                      <!-- 直接上级 -->
                      <div
                        class="select_input"
                        v-if="kpiBaseInfo.show_zj_score"
                      >
                        <h3>{{ $t('jxDataSocreDetail.txt24') }}</h3>
                        <div class="ipt_div">
                          <input
                            :disabled="true"
                            type="text"
                            v-model="item.zjsj_score"
                          />
                        </div>
                      </div>
                      <div class="desc_input" v-if="kpiBaseInfo.show_zj_pj">
                        <h3>{{ $t('jxDataSocreDetail.txt10') }}</h3>
                        <div class="thrid_desc">
                          <div class="desp">{{ item.zjdesc }}</div>
                        </div>
                      </div>
                      <!-- 间接上级 -->
                      <div
                        class="select_input"
                        v-if="kpiBaseInfo.show_jj_score"
                      >
                        <h3>{{ $t('jxDataSocreDetail.txt25') }}</h3>
                        <div class="ipt_div">
                          <input
                            :disabled="true"
                            type="text"
                            v-model="item.jjsj_score"
                          />
                        </div>
                      </div>
                      <div class="desc_input" v-if="kpiBaseInfo.show_jj_pj">
                        <h3>{{ $t('jxDataSocreDetail.txt11') }}</h3>
                        <div class="thrid_desc">
                          <div class="desp">{{ item.jjdesc }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="hgyfkdatasupplyremark">
                      <van-field
                        v-model="item.hgyfk"
                        :label="'回顾及反馈频率'"
                        label-width="7em"
                        readonly
                        input-align="right"
                      />
                      <van-field
                        v-model="item.datasupply"
                        :label="'数据提供责任人'"
                        label-width="7em"
                        readonly
                        input-align="right"
                      />
                      <van-collapse
                        class="thrid_coll"
                        v-model="activeNames8"
                        @change="item.remarkstatus = !item.remarkstatus"
                      >
                        <van-collapse-item
                          :name="ind + 'remark' + idx"
                          :title="'备注'"
                        >
                          <div class="thrid_desc">
                            <div class="desp">{{ item.remark }}</div>
                          </div>
                        </van-collapse-item>
                      </van-collapse>
                    </div>
                  </van-collapse-item>
                </van-collapse>
              </van-collapse-item>
            </van-collapse>
          </div>
        </van-list>
      </van-pull-refresh>
      <!-- <div class="ss">
        <van-button
          type="info"
          v-if="
            activeName == 'KPITag' &&
              !kpiBaseInfo.isss &&
              kpiBaseInfo.canss &&
              kpiBaseInfo.sstype == '按结果申诉'
          "
          @click="toShensu(jgitem)"
          >我要申诉</van-button
        >
      </div> -->
    </div>
    <div class="footer">
      <van-button
        type="warning"
        size="small"
        @click="_myKhScoreAndLevelCheck(0)"
        >分数确认</van-button
      >
      <van-button type="info" size="small" @click="_myKhScoreAndLevelCheck(1)"
        >等级确认</van-button
      >
      <van-button
        v-if="
          activeName == 'KPITag' &&
            !kpiBaseInfo.isss &&
            kpiBaseInfo.canss &&
            kpiBaseInfo.sstype == '按结果申诉'
        "
        type="primary"
        size="small"
        @click="toShensu(jgitem)"
        >我要申诉</van-button
      >
    </div>
    <van-popup
      v-model="showPicker"
      :close-on-click-overlay="false"
      :close-on-popstate="false"
      round
      position="bottom"
    >
      <van-picker
        :title="'请选择考核周期'"
        show-toolbar
        :columns="
          khTimeList.map(e => {
            return e.khtimename
          })
        "
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <!-- 签名弹窗 -->
    <van-overlay :show="showSign" @click="showSign = false">
      <div style="width: 100%; height: 100%" class="wrapper" @click.stop>
        <signaturePad
          @getSpad="getSpad"
          @closeSpad="showSign = false"
          :show="showSign"
        ></signaturePad>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Toast, Popup, Picker } from 'vant'
import {
  getMy360HisRecList,
  getMyKpiHisRecList,
  get_My_Kpi_HisRecDetail_ForKhTime,
  get_My_360_HisRecDetail_ForKhTime,
  get_My_Gs_HisRecDetail_ForKhTime,
  getKhTimeList,
  myKhScoreAndLevelCheck,
  upLoadBase64FileFixName
} from '@api/wxjx.js'
import signaturePad from '@components/signaturePad'
export default {
  components: {
    signaturePad
  },
  data() {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    return {
      userInfo,
      activeName: '360Tag',
      isLoadingAwait: false,
      loadingAwait: false,
      finishedAwait: false,
      showPicker: false,
      assessList: [],
      khtime: { autid: 0 },
      khTimeList: [],
      activeNames1: [],
      activeNames2: [],
      activeNames3: [],
      activeNames4: [],
      activeNames5: [],
      activeNames6: [],
      activeNames7: [],
      activeNames8: [],
      kpiBaseInfo: {},
      jgitem: {
        mautoid: 0,
        lastscore: 0,
        khres: '',
        itemautoid: 0,
        tgvalue: 0,
        itemper: '',
        realscore: 0
      }, // 按结果申诉时传参
      use_last_sign_pic: false, // 是否启用上次签名
      forbit_edit_last_sign: false, // 上次签名是否可修改
      showSign: false
    }
  },
  created() {},
  beforeRouteEnter(to, from, next) {
    let is360 = true
    if (
      from.name == 'jxMyAssResultsList' ||
      from.name == 'jxMyAssResultsAppeal'
    ) {
      is360 = from.query.is360
    }
    next(vm => {
      //因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
      if (is360 == true || is360 == 'true') {
        vm.activeName = '360Tag'
      } else {
        vm.activeName = 'KPITag'
      }
    })
  },
  methods: {
    onConfirm(value, index) {
      this.setKhtime(this.khTimeList[index])
    },
    setKhtime(n) {
      this.khtime = n
      if (
        (this.activeName == 'GSTag' && n.isgs) ||
        (this.activeName == 'KPITag' && n.iskpi) ||
        (this.activeName == '360Tag' && n.is360)
      ) {
      } else {
        this.activeName = n.is360 ? '360Tag' : n.iskpi ? 'KPITag' : 'GSTag'
      }
      this.showPicker = false
      this.onLoadAwait()
      Toast(this.$t('module.refreshSuccessfully'))
    },
    _getKhTimeList() {
      getKhTimeList({
        username: this.userInfo.username,
        flag: 0
      }).then(async res => {
        this.khTimeList = res.data
        if (res.data.length > 0) {
          if (this.$route.query.isGS == '1') {
            this.activeName = 'GSTag'
            for (let index = 0; index < res.data.length; index++) {
              const element = res.data[index]
              if (element.autoid == this.$route.query.khtimeid) {
                this.setKhtime(element)
              }
            }
          } else {
            this.setKhtime(res.data[0])
          }
        }
        this.onLoadAwait()
        Toast(this.$t('module.refreshSuccessfully'))
      })
    },
    onClickLeft() {
      this.$router.push('/home')
    },
    // 申诉按钮
    applyAppeal(item) {
      let is360 = this.activeName == '360Tag'
      this.$router.push({
        path: '/jxMyAssResultsAppeal',
        query: {
          is360: is360,
          mautoid: item.autoid,
          lastscore: item.lastscore,
          khres: item.khres,
          khtimename: item.khtimename,
          planname: item.planname,
          sstype: item.sstype,
          itemautoid: item.itemautoid,
          khtimeid: this.khtime.autoid
        }
      })
    },
    /// 8.1.获取我的历史360考核方案列表
    getMy360His() {
      let _this = this
      get_My_360_HisRecDetail_ForKhTime({
        empid: this.userInfo.empid,
        khtimeid: this.khtime.autoid
      }).then(res => {
        if (res.data.length == 0) {
          _this.finishedAwait = true
          // 无数据
        } else {
          let ary = []
          let afterData = []
          let tempArr = []
          let resData = res.data
          // let ary = resData.filter(item => { return item.pjr_empname + item.checkflagname })
          if (resData.length > 0) {
            resData.forEach(ele => {
              ele.name = ele.pjr_empname + '-' + ele.checkflagname
              ary.push(ele)
            })
            for (let i = 0; i < ary.length; i++) {
              if (tempArr.indexOf(ary[i].name) === -1) {
                afterData.push({
                  name: ary[i].name,
                  origin: [ary[i]]
                })
                tempArr.push(ary[i].name)
              } else {
                for (let j = 0; j < afterData.length; j++) {
                  if (afterData[j].name == ary[i].name) {
                    afterData[j].origin.push(ary[i])
                    break
                  }
                }
              }
            }
          }
          _this.assessList = afterData
          _this.loadingAwait = false
          _this.finishedAwait = true
        }
      })
    },
    // 8.2.获取我的历史KPI考核方案列表
    getMyKpiHis() {
      let _this = this
      get_My_Kpi_HisRecDetail_ForKhTime({
        empid: this.userInfo.empid,
        khtimeid: this.khtime.autoid
      }).then(res => {
        if (res.data.length == 0) {
          _this.finishedAwait = true
          // 无数据
        } else {
          let ary = []
          let afterData = []
          let tempArr = []
          this.kpiBaseInfo = res.baseinfo[0]
          this.jgitem.itemautoid = res.baseinfo[0].autoid
          let resData = res.data
          let tjList = res.itemlist
          // let ary = resData.filter(item => { return item.pjr_empname + item.checkflagname })
          if (resData.length > 0) {
            resData.forEach((ele, idx) => {
              ele.name = ele.itemtype
              // + '-' + ele.itemname
              ele.itemdesc = ele.itemdesc
                ? ele.itemdesc.replace(/\\n/g, '\n')
                : ''
              ele.showgz = false // 显示评分规则
              ele.showtj = false // 显示统计项目
              ele.showzw = false // 显示自我描述
              ele.zjdespstatus = false // 直接上级描述
              ele.jjdespstatus = false // 间接上级描述
              ele.remarkstatus = false // 备注
              ele.child = [] // 统计项目
              if (tjList.length > 0) {
                tjList.forEach(it => {
                  if (ele.itemautoid == it.itemautoid) {
                    ele.child.push(it)
                  }
                })
              }
              ary.push(ele)
              this.activeNames1.push(idx)
            })
            for (let i = 0; i < ary.length; i++) {
              if (tempArr.indexOf(ary[i].name) === -1) {
                afterData.push({
                  name: ary[i].name,
                  lastscore: ary[i].lastscore,
                  khres: ary[i].khres,
                  origin: [ary[i]]
                })
                tempArr.push(ary[i].name)
              } else {
                for (let j = 0; j < afterData.length; j++) {
                  if (afterData[j].name == ary[i].name) {
                    afterData[j].origin.push(ary[i])
                    break
                  }
                }
              }
            }
          }
          _this.assessList = afterData
          _this.loadingAwait = false
          _this.finishedAwait = true
        }
      })
    },
    // 8.3.获取我的历史GS考核方案列表
    getMyGSHis() {
      let _this = this
      get_My_Gs_HisRecDetail_ForKhTime({
        empid: this.userInfo.empid,
        khtimeid: this.khtime.autoid
      }).then(res => {
        if (res.data.length == 0) {
          _this.finishedAwait = true
          // 无数据
        } else {
          _this.assessList = res.data
          _this.loadingAwait = false
          _this.finishedAwait = true
        }
      })
    },
    onLoadAwait() {
      if (this.khtime.autid == 0) {
        this._getKhTimeList()
        return
      }

      // 异步更新数据
      if (this.isLoadingAwait) {
        this.assessList = []
        this.isLoadingAwait = false
      }
      if (this.activeName == '360Tag') {
        this.getMy360His()
      } else if (this.activeName == 'KPITag') {
        this.getMyKpiHis()
      } else {
        this.getMyGSHis()
      }
    },
    async onRefreshAwait() {
      this.assessList = []
      this.finishedAwait = false
      this.loadingAwait = true
      this.onLoadAwait()
      Toast(this.$t('module.refreshSuccessfully'))
    },
    // tab切换事件
    changeAct(name, title) {
      this.onRefreshAwait()
    },
    // 发起指标申请
    goToApply(item) {
      let is360 = true
      let is_jjp = null
      let is_zjp = null
      let is_zp = null

      let is_khcheck = null
      let is_send_khcheck = null
      const mautoid = item.autoid
      if (this.activeName == '360Tag') {
        is360 = true
      } else {
        is360 = false
        is_jjp = item.is_jjp
        is_zjp = item.is_zjp
        is_zp = item.is_zp
        is_khcheck = item.is_khcheck
        is_send_khcheck = item.is_send_khcheck
      }
      // console.log(item,'1111111111')
      if (this.activeName == 'GSTag') {
        this.$router.push({
          path: '/queTaskApply',
          query: {
            title: item.taskname,
            isSure: true,
            autoid: item.autoid,
            from: '/jxMyAssResults?khtimeid=' + this.khtime.autoid
          }
        })
      } else {
        let obj = {
          title: item.khtimename,
          khname: item.planname,
          is360: is360,
          mautoid: mautoid,
          lastscore: item.lastscore,
          is_jjp: is_jjp,
          is_zjp: is_zjp,
          is_zp: is_zp,
          sjtype: item.sstype,
          cansj: item.canss,
          khres: item.khres,
          is_khcheck: is_khcheck,
          is_send_khcheck: is_send_khcheck
        }
        let str = JSON.stringify(obj)
        window.sessionStorage.setItem('goToApplyObj', str)
        this.$router.push({
          path: '/jxMyAssResultsList',
          query: obj
        })
      }
    },
    toShensu(item) {
      console.log(item, 'itemitem')
      // return
      // console.log(this.khname, "this.khname.khnameitem");
      this.$router.push({
        path: '/jxMyAssResultsAppeal',
        query: {
          is360: false, //this.is360,
          mautoid: item.autoid,
          lastscore: item.totalscore,
          khres: item.itemname,
          khtimename: this.khtime.khtimename,
          planname: this.kpiBaseInfo.planname,
          sstype: this.kpiBaseInfo.sstype,
          isshensu: false,
          itemautoid: this.kpiBaseInfo.autoid,
          tgvalue: item.tgvalue,
          itemper: item.itemper,
          realscore: item.realscore,
          khtimeid: this.khtime.autoid
        }
      })
    },
    // 分数确认/等级确认
    _myKhScoreAndLevelCheck(flag) {
      myKhScoreAndLevelCheck({
        flag,
        empid: this.userInfo.empid,
        khtimeid: this.khtime.autoid
      }).then(res => {
        if (res.data[0].info) {
          Toast.fail('失败！' + res.data[0].info)
        } else {
          if (res.data[0].issgin && res.data[0].mautoid) {
            /*
            如果info等空，并且issgin=true，则弹出签名框，手写签名后再提示确认成功！
手写签名图片附件保存到模块555中，其中 mautoid 表示555模块的autoid字段；
调用通用的保存附件接口：upLoadFile 来保存图片；
            */
            this.CheckMautoid = res.data[0].mautoid
            this.showSign = true
          } else {
            Toast.success('确认成功！')
          }
        }
      })
    },
    // 签名弹窗提交回调
    // 签名提交的回调
    getSpad(url) {
      let data = {
        filebase64str: url,
        upflag: 2,
        moduleno: 555,
        autoid: this.CheckMautoid,
        uploadpath: 'AttachFile',
        filename: 'kh_check_sign.png',
        fileext: 'png',
        username: this.userInfo.username
      }
      // 先保存签名
      upLoadBase64FileFixName(data).then(res => {
        if (res == 'OK') {
          Toast.success('确认成功！')
          this.showSign = false
        } else {
          Toast(res)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.myAssResult {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
      .van-nav-bar__text {
        color: #fff;
      }
    }
    /deep/ .van-nav-bar__right {
      color: #fff;
      .van-icon {
        color: #fff;
      }
      .van-nav-bar__text {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    // overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 200px);
    /deep/.van-tabs {
      .van-tab {
        .van-tab__text {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
        }
      }
    }
    .van-pull-refresh {
      margin-top: 30px;
      overflow: auto;
      height: calc(100% - 100px);
    }
    .van-pull-refresh.hasss {
      height: calc(100% - 220px);
    }
    .ss {
      width: 100%;
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .van-button {
        width: 90vw;
      }
    }
    .zb_group {
      .tkpitag {
        padding: 10px;
        font-size: 32px;
        .tkpitag_h {
          margin: 0 15px;
          color: #ff9600;
        }
        .tkpitag_p {
          margin: 10px;
          .tkpitag_p_h {
            padding: 15px 10px;

            background: #2b8df0;
            color: #fff;
            border-radius: 10px 10px 0 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .tkpitag_p_b {
            background: #fff;
            padding: 15px 10px;
            .tkpitag_p_b_l {
              padding: 15px;
              border-bottom: 1px solid #ccc;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .tkpitag_p_b_l_v {
                color: #2b8df0;
                .van-icon {
                  color: #999;
                }
              }
            }
          }
        }
      }
      .ass_cell2 {
        margin: 10px 0;
        .van-cell__title {
          flex: 3;
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          line-height: 54px;
          color: #333333;
        }
      }
    }

    .zb_title {
      background-color: #fff;
      margin: 20px;
      border-radius: 12px;
      .til_div {
        padding: 10px 0 0 10px;
        text-align: left;
        color: #333333;
        font-weight: bold;
        font-size: 36px;
        font-family: Source Han Sans CN;
      }
      .zb_group {
        // display: flex;
        border-left: 6px solid #2b8df0;
        margin: 30px 0;
        .van-cell-group {
          .top_cell {
            .van-cell__title {
              font-size: 32px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #2b8df0;
            }
            .van-cell__value {
              font-size: 32px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #333333;
            }
          }
          .btm_cell {
            .van-cell__title {
              font-size: 28px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #333333;
              .custom-title {
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
              }
            }
            .van-cell__label {
              text-align: right;
              font-size: 28px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #333333;
              .custom-score {
                font-size: 48px;
                font-family: DIN;
                font-weight: bold;
                color: #2b8df0;
              }
            }
          }
        }
      }
    }

    .first_coll {
      padding: 20px 30px;
      .zf {
        color: #ff9600;
        font-size: 32px;
        padding-bottom: 10px;
        .svg-icon {
          margin-right: 10px;
        }
      }
      /deep/.van-collapse-item {
        border-radius: 12px 12px 0 0;
        margin-bottom: 30px;
        .van-cell::after {
          border-bottom: unset;
        }
        .van-collapse-item__title {
          padding: 10px 15px;
          text-align: center;
          border-radius: 12px 12px 0 0;
          background: #2b8df0;
          .van-cell__title {
            color: #fff;
            font-weight: bold;
            font-size: 32px;
            font-family: Source Han Sans CN;
          }
          .van-icon {
            color: #fff;
          }
        }
        .van-collapse-item__wrapper {
          .van-collapse-item__content {
            padding: 10px 20px;
            .disabled_coll {
              .van-collapse-item {
                .van-collapse-item__title {
                  .van-icon {
                    color: #ffffff !important;
                  }
                }
              }
            }
            .second_coll {
              .van-collapse-item {
                border-radius: unset;
                border-bottom: 0.02667rem solid #ebedf0;
                .van-cell::after {
                  border-bottom: 0.02667rem solid #ebedf0;
                }
                .van-collapse-item__title {
                  padding: 20px 30px;
                  border-radius: unset;
                  background: #fff;
                  .van-cell__title {
                    color: #333333;
                    font-weight: unset;
                    font-size: 32px;
                    font-family: Source Han Sans CN;
                    .second_title {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .second_name {
                        width: 68%;
                        text-align: left;
                        font-weight: bold;
                      }
                      .second_score {
                        color: #2b8df0;
                        margin-right: 10px;
                      }
                    }
                  }
                  .van-icon {
                    color: #333333;
                  }
                }
                .van-collapse-item__wrapper {
                  .van-collapse-item__content {
                    .second_details {
                      margin-bottom: 16px;
                      .thrid_desc {
                        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
                        border-radius: 12px;
                        padding: 20px;
                        .itemdesc {
                          color: #666666;
                          font-size: 24px;
                        }
                        .desc {
                          display: flex;
                          justify-content: space-between;
                          padding: 16px 0;
                          .thrid_per {
                            color: #333333;
                          }
                          .thrid_tg {
                            color: #2b8df0;
                          }
                        }
                      }
                    }
                    .thrid_coll {
                      .van-collapse-item {
                        border-radius: unset;
                        border-bottom: unset;
                        .van-cell::after {
                          border-bottom: unset;
                        }
                        .van-collapse-item__title {
                          text-align: left;
                          padding: 0;
                          .van-cell__title {
                            color: #999999;
                            font-size: 28px;
                          }
                          .right-icon {
                            display: flex;
                            align-items: center;
                            color: #2b8df0;
                            font-size: 24px;
                            .van-icon {
                              color: #2b8df0;
                            }
                          }
                        }
                        .van-collapse-item__wrapper {
                          .van-collapse-item__content {
                            padding: 0;
                            .thrid_desc {
                              // box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
                              // border-radius: 12px;
                              margin: 16px 4px 4px 4px;
                              .desp {
                                box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
                                border-radius: 12px;
                                padding: 20px;
                                color: #666666;
                                font-size: 24px;
                                min-height: 5em;
                                .Sta_poj {
                                  padding-bottom: 12px;
                                  color: #333333;
                                  display: flex;
                                  justify-content: space-between;
                                  border-bottom: 0.02667rem solid #ebedf0;
                                }
                              }
                            }
                            // .thrid_Sta {
                            //   box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
                            //   border-radius: 12px;
                            //   margin: 16px 4px 4px 4px;
                            //   min-height: 5em;

                            // }
                          }
                        }
                      }
                    }
                    .pingjia {
                      margin-bottom: 20px;
                      display: flex;
                      justify-content: space-between;
                      .pj_sco {
                        .text {
                          padding: 0 4px;
                          border: 1px solid #f53838;
                          background: #f53838;
                          border-radius: 50%;
                          color: #fff;
                        }
                        .time {
                          color: #666666;
                          padding: 0 10px;
                        }
                      }
                    }
                    .van-hairline--top-bottom::after {
                      border-width: 0;
                    }

                    .select_container {
                      width: 100%;
                      display: flex;
                      justify-content: space-around;
                      margin-top: 0.4rem;
                      .select_input {
                        width: 25%;
                        div {
                          text-align: center;
                        }
                        h3 {
                          margin: 0;
                          font-size: 28px;
                          color: #666;
                          text-align: center;
                        }
                        input {
                          width: 80%;
                          height: 66px;
                          background: #ffffff;
                          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.18);
                          border-radius: 10px;
                          border: 0;
                          margin-bottom: 6px;
                          margin-top: 12px;
                          text-align: center;
                          color: #2b8df0;
                          font-size: 36px;
                        }
                      }
                    }

                    .zhijie_jianjie_pj {
                      .select_input {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        h3 {
                          margin: 0;
                          font-size: 28px;
                          color: #666;
                          text-align: center;
                        }
                        .ipt_div {
                          text-align: right;
                          input {
                            margin-right: 10%;
                            width: 30%;
                            height: 66px;
                            background: #ffffff;
                            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.18);
                            border-radius: 10px;
                            border: 0;
                            margin-bottom: 6px;
                            margin-top: 12px;
                            text-align: center;
                            color: #2b8df0;
                            font-size: 36px;
                          }
                        }
                      }
                      .desc_input {
                        h3 {
                          text-align: left;
                          margin: 0;
                          font-size: 28px;
                          color: #666;
                        }
                        .thrid_desc {
                          margin: 16px 4px 4px 4px;
                          .desp {
                            margin-right: 6%;
                            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
                            border-radius: 12px;
                            padding: 20px;
                            color: #666666;
                            font-size: 24px;
                            min-height: 5em;
                            .Sta_poj {
                              padding-bottom: 12px;
                              color: #333333;
                              display: flex;
                              justify-content: space-between;
                              border-bottom: 0.02667rem solid #ebedf0;
                            }
                          }
                        }
                      }
                    }

                    .hgyfkdatasupplyremark {
                      .van-field {
                        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
                        border: 0;
                      }
                      .thrid_coll {
                        margin-top: 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .van-collapse-item--border::after {
        border-top: unset;
      }
    }
  }
  .footer {
    position: absolute;
    background-color: #ffffff;
    bottom: 0;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    /* text-align: center; */
    justify-content: center;
    .van-button {
      flex: 1;
      border-radius: 12px;
      margin: 0 5px;
      .van-button__text {
        font-size: 36px;
        font-family: Source Han Sans SC;
        font-weight: 500;
      }
    }
  }
}
</style>
